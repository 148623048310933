import store from '@/store'

export function authGuard(to, from, next) {
  const token = localStorage.getItem('token')
  const expire = localStorage.getItem('expire');

  if (!token) {
    next('/pages/login')
  } else {
    if(expire && expire < new Date().getTime()){
      store.commit("admin/logout")
    }
    // If the token exists, allow the user to access the protected route
    next()
  }
}
