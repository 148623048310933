<template>
  <CHeader
    position="sticky"
    class="mb-4 is-pc"
    :style="[
      !isDashboard()
        ? { background: '#FF0001', color: 'white' }
        : { background: '#ffffff' },
    ]"
  >
    <CContainer fluid>
      <CHeaderToggler class="ps-1">
        <router-link to="/dashboard" class="text-decoration-none">
          <div class="text-center d-flex align-items-center">
            <div class="mx-2">
              <span
                :class="isDashboard() ? 'color-primary' : 'text-white'"
                style="font-size: 24px"
              >
                PIPIT
              </span>
            </div>
          </div>
        </router-link>
      </CHeaderToggler>
      <CHeaderNav class="">
        <router-link to="/messages">
          <CNavItem
            style="border-left: 1px solid #fff"
            v-if="$route.path !== '/dashboard'"
          >
            <CNavLink class="text-center px-4 py-1">
              <CIcon
                style="width: 26px; height: 26px"
                class="mx-2 text-white fa-flip-horizontal"
                icon="cil-speech"
                size="lg"
              />
              <p class="m-0 text-white">メッセージ配信</p>
            </CNavLink>
          </CNavItem>
        </router-link>
        <router-link to="/services">
          <CNavItem
            style="border-left: 1px solid #fff; border-right: 1px solid #fff"
            v-if="$route.path !== '/dashboard'"
          >
            <CNavLink class="text-center px-4 py-1">
              <CIcon
                style="width: 26px; height: 26px"
                class="mx-2 text-white"
                icon="cil-calendar"
                size="lg"
              />
              <p class="m-0 text-white">予約管理</p>
            </CNavLink>
          </CNavItem>
        </router-link>
        <router-link to="/users">
          <CNavItem
            style="border-left: 1px solid #fff"
            v-if="$route.path !== '/dashboard'"
          >
            <CNavLink class="text-center px-4 py-1">
              <CIcon
                style="width: 26px; height: 26px"
                class="mx-2 text-white"
                icon="cil-user"
                size="lg"
              />
              <p class="m-0 text-white">顧客管理</p>
            </CNavLink>
          </CNavItem>
        </router-link>
        <router-link to="/settings">
          <CNavItem
            style="border-left: 1px solid #fff"
            v-if="$route.path !== '/dashboard'"
          >
            <CNavLink class="text-center px-4 py-1">
              <CIcon
                style="width: 26px; height: 26px"
                class="mx-2 text-white fa-flip-horizontal"
                icon="cil-settings"
                size="lg"
              />
              <p class="m-0 text-white">テキスト編集</p>
            </CNavLink>
          </CNavItem>
        </router-link>
        <!-- select branch -->
        <CNavItem style="border-left: 1px solid #fff" v-if="isShowChooseApp()">
          <CDropdown>
            <CDropdownToggle
              class="text-center px-4 py-1 nav-dropdown-toggle-branch-button"
            >
              <CIcon
                style="width: 28px; height: 28px"
                class="mx-2"
                :class="{ 'text-white': !isDashboard() }"
                icon="cib-emlakjet"
                size="lg"
              />
              <p class="m-0 text-white nav-dropdown-toggle-branch">
                {{ currentBranch?.name }}
              </p>
            </CDropdownToggle>
            <CDropdownMenu style="font-size: 14px" class="p-0">
              <template v-for="(branch, index) in branches" :key="branch.id">
                <CDropdownItem @click="changeBranch(branch.id)" class="pe-auto">
                  <div class="pe-auto">
                    <p class="m-auto">{{ branch.name }}</p>
                  </div>
                </CDropdownItem>
                <CDropdownDivider
                  class="m-0"
                  v-if="index < branches.length - 1"
                />
              </template>
            </CDropdownMenu>
          </CDropdown>
        </CNavItem>
        <!-- end select branch -->
        <CNavItem>
          <CNavLink>
            <CDropdown>
              <CDropdownToggle>
                <CIcon
                  style="width: 28px; height: 28px"
                  class="mx-2"
                  :class="{ 'text-white': !isDashboard() }"
                  icon="cil-bell"
                  size="lg"
                />
                <span class="notification shadow-sm">{{
                  notificationUnread
                }}</span>
              </CDropdownToggle>
              <CDropdownMenu style="font-size: 14px" class="p-0">
                <template v-for="(item, index) in message.notifications.data" :key="item.messages_id">
                  <CDropdownItem :style="[item.messages_is_read == 0 ? { 'background': '#fff' } : { 'background': 'grey' }]"
                    :class="{ 'bg-opacity-50': item.messages_is_read == 0 }">
                    <div @click="readNotification(item.messages_id, item.messages_is_read, item.is_fuel)" :class="{ 'text-black': item.messages_is_read == 0 }">
                      <p class="m-auto">{{ item.user_name_kana }} <span class="float-lg-end">{{
                        this.timeDiff(item.booking_created_at) }}</span></p>
                      <p class="m-auto" v-if="item.booking_date !== null">{{ this.formatDate(item.booking_date) }}
                        <template v-if="item.start_time && item.end_time">
                          {{ item.start_time }} ～ {{ item.end_time }}
                        </template>
                      </p>
                      <p class="m-auto">
                        {{
                          item.gas_station_name
                        }}<span v-if="item.gas_station_name">/</span>{{
                          item.service_name
                        }}が予約されました
                      </p>
                    </div>
                  </CDropdownItem>
                  <CDropdownDivider
                    class="m-0"
                    v-if="index < message.notifications.data.length - 1"
                  />
                </template>
              </CDropdownMenu>
            </CDropdown>
          </CNavLink>
        </CNavItem>
        <AppHeaderDropdownAccount />
      </CHeaderNav>
    </CContainer>
    <!--    <CHeaderDivider />-->
    <!--    <CContainer fluid>-->
    <!--      <AppBreadcrumb />-->
    <!--    </CContainer>-->
  </CHeader>
  <!-- Mobile -->
  <CHeader
    position="sticky"
    class="is-mobile"
    :style="{ background: '#FF0001' }"
  >
    <div style="margin: 0 5px;">
      <div class="d-flex align-items-center justify-content-between">
        <div class="hamburger-menu" :class="{'change': showMobileMenu}" @click="showMobileMenu = !showMobileMenu">
          <div class="bar1" :class="{'change': showMobileMenu}"></div>
          <div class="bar2" :class="{'change': showMobileMenu}"></div>
          <div class="bar3" :class="{'change': showMobileMenu}"></div>
        </div>
        <router-link to="/dashboard" class="text-decoration-none">
          <div class="text-center d-flex align-items-center">
            <div class="mx-2">
              <span
                class="text-white"
                style="font-size: 22px"
              >
                PIPIT
              </span>
            </div>
          </div>
        </router-link>
        <CNavLink>
          <CDropdown>
            <CDropdownToggle>
              <CIcon
                style="width: 28px; height: 28px"
                class="text-white"
                icon="cil-bell"
                size="lg"
              />
              <span class="notification shadow-sm">{{
                  notificationUnread
                }}</span>
            </CDropdownToggle>
            <CDropdownMenu style="font-size: 14px" class="p-0">
              <template v-for="(item, index) in message.notifications.data" :key="item.messages_id">
                <CDropdownItem :style="[item.messages_is_read == 0 ? { 'background': '#fff' } : { 'background': 'grey' }]"
                               :class="{ 'bg-opacity-50': item.messages_is_read == 0 }">
                  <div @click="readNotification(item.messages_id, item.messages_is_read)" :class="{ 'text-black': item.messages_is_read == 0 }">
                    <p class="m-auto">{{ item.user_name_kana }} <span class="float-lg-end">{{
                        this.timeDiff(item.booking_created_at) }}</span></p>
                    <p class="m-auto" v-if="item.booking_date !== null">{{ this.formatDate(item.booking_date) }}
                      <template v-if="item.start_time && item.end_time">
                        {{ item.start_time }} ～ {{ item.end_time }}
                      </template>
                    </p>
                    <p class="m-auto">
                      {{
                      item.gas_station_name
                      }}<span v-if="item.gas_station_name">/</span>{{
                      item.service_name
                      }}が予約されました
                    </p>
                  </div>
                </CDropdownItem>
                <CDropdownDivider
                  class="m-0"
                  v-if="index < message.notifications.data.length - 1"
                />
              </template>
            </CDropdownMenu>
          </CDropdown>
        </CNavLink>
      </div>
    </div>
    <div class="is-mobile mobile-menu-container" :class="{'d-none': !showMobileMenu}">
      <div class="mobile-menu-list">
        <div class="mobile-menu-item" @click="navigateTo('/messages')">
          <CIcon
            style="width: 26px; height: 26px"
            class="mx-2 text-white fa-flip-horizontal"
            icon="cil-speech"
            size="lg"
          />
          <p class="m-0 text-white">メッセージ配信</p>
        </div>
        <div class="mobile-menu-item" @click="navigateTo('/services')">
          <CIcon
            style="width: 26px; height: 26px"
            class="mx-2 text-white"
            icon="cil-calendar"
            size="lg"
          />
          <p class="m-0 text-white">予約管理</p>
        </div>
        <div class="mobile-menu-item" @click="navigateTo('/users')">
          <CIcon
            style="width: 26px; height: 26px"
            class="mx-2 text-white"
            icon="cil-user"
            size="lg"
          />
          <p class="m-0 text-white">顧客管理</p>
        </div>
        <div class="mobile-menu-item" @click="navigateTo('/settings')">
          <CIcon
            style="width: 26px; height: 26px"
            class="mx-2 text-white fa-flip-horizontal"
            icon="cil-settings"
            size="lg"
          />
          <p class="m-0 text-white">テキスト編集</p>
        </div>
        <div class="mobile-menu-item" v-if="isShowChooseApp()">
          <CDropdown>
            <CDropdownToggle
              class="d-flex align-items-center nav-dropdown-toggle-branch-button"
              style="margin-left: -5px;"
            >
              <CIcon
                style="width: 28px; height: 28px"
                class="text-white"
                icon="cib-emlakjet"
                size="lg"
              />
              <p class="m-0 text-white nav-dropdown-toggle-branch" style="padding-left: 10px;">
                {{ currentBranch?.name }}
              </p>
            </CDropdownToggle>
            <CDropdownMenu style="font-size: 14px" class="p-0">
              <template v-for="(branch, index) in branches" :key="branch.id">
                <CDropdownItem @click="changeBranch(branch.id)" class="pe-auto">
                  <div class="pe-auto">
                    <p class="m-auto">{{ branch.name }}</p>
                  </div>
                </CDropdownItem>
                <CDropdownDivider
                  class="m-0"
                  v-if="index < branches.length - 1"
                />
              </template>
            </CDropdownMenu>
          </CDropdown>
        </div>
        <div class="mobile-menu-item" @click="mobileLogout">
          <CIcon
            style="width: 26px; height: 26px"
            class="mx-2 text-white"
            icon="cil-arrow-bottom"
            size="lg"
          />
          <p class="m-0 text-white">ログアウト</p>
        </div>
      </div>
    </div>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccount from './AppHeaderDropdownAccnt'
import store from "@/store";
import router from "@/router";
import { mapState } from 'vuex'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import moment from 'moment'
import 'moment/locale/ja'
import moment_time_zone from 'moment-timezone';
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import { useSound } from '@vueuse/sound'

const BranchesRepository = RepositoryFactory.get('branches')

export default {
  name: 'AppHeader',
  components: {
    // AppBreadcrumb,
    AppHeaderDropdownAccount,
  },

  setup() {
    const notificationSound = useSound('notification_sound.wav')
    const echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_PUSHER_APP_KEY,
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      encrypted: true,
    })
    return {
      notifications: [],
      echo,
      notificationSound
    }
  },
  data() {
    return {
      branches: [],
      // current branch
      currentBranch: null,
      showMobileMenu: false,
    }
  },

  mounted() {
    this.echo.channel('new-booking').listen('NewBookingEvent', (booking) => {
      this.notificationSound.play();
      this.getNotifications()
    })
    // load notifications
    this.getNotifications()
    // load branches
    this.getBranches()
  },
  methods: {
    // kiem tra route hien tai co phai la user khong
    isShowChooseApp() {
      if (
        this.$route.path === '/users' ||
        this.$route.path === '/messages' ||
        // hoac /messages/*
        this.$route.path.includes('/messages/')
      ) {
        return true
      }
      return false
    },
    // get branch
    getBranches() {
      if (localStorage.getItem('branches') !== null) {
        this.branches = JSON.parse(localStorage.getItem('branches'))
      } else {
        BranchesRepository.getBranches()
          .then((response) => {
            this.branches = response.data.data
            // store into local storage  branches
            localStorage.setItem('branches', JSON.stringify(this.branches))
            // store into local storage current branch
            if (localStorage.getItem('currentBranch') === null) {
              this.currentBranch = this.branches[0]
              localStorage.setItem(
                'currentBranch',
                JSON.stringify(this.currentBranch),
              )
            } else {
              this.currentBranch = JSON.parse(
                localStorage.getItem('currentBranch'),
              )
            }
          })
          .catch((error) => {
            console.log(error.code)
          })
      }

      if (this.branches.length > 0) {
        // store into local storage current branch
        if (localStorage.getItem('currentBranch') === null) {
          this.currentBranch = this.branches[0]
          localStorage.setItem(
            'currentBranch',
            JSON.stringify(this.currentBranch),
          )
        } else {
          this.currentBranch = JSON.parse(localStorage.getItem('currentBranch'))
        }
      }
    },

    getNotifications() {
      this.$store
        .dispatch('message/getNotifications')
        .then(() => {
          this.notifications = this.message.notifications.data
        })
        .catch((error) => {
          console.log(error.code)
        })
    },

    readNotification(id, isRead, isFuel) {
      if (!isRead) {
        this.$store
          .dispatch('message/updateNotification', { id: id, is_fuel: isFuel})
          .then(() => {
            this.getNotifications()
          })
          .catch((error) => {
            console.log(error.code)
          })
      }
    },

    formatDate(date) {
      return moment(date).format('YYYY年M月D日')
    },

    timeDiff(date) {
      const today = moment_time_zone().tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')
      const day = moment(date)
      const difference = day.diff(today)
      const duration = moment.duration(difference)
      moment.locale('ja');

      return duration.humanize()
    },

    isDashboard() {
      return this.$route.path === '/dashboard'
    },

    changeBranch(id) {
      this.currentBranch = this.branches.find((branch) => branch.id === id)
      // store into local storage current branch
      // check if current branch is change then reload page
      if (
        JSON.parse(localStorage.getItem('currentBranch')).id !==
        this.currentBranch.id
      ) {
        localStorage.setItem(
          'currentBranch',
          JSON.stringify(this.currentBranch),
        )
        // reload page
        location.reload()
      }
    },
    mobileLogout() {
      // remove all local storage
      localStorage.clear()
      store.commit("admin/logout")
      router.push({ path: '/pages/login' })
    },
    navigateTo(link) {
      this.showMobileMenu = !this.showMobileMenu
      router.push({ path: link })
    }
  },
  computed: {
    ...mapState(['message']),

    notificationUnread() {
      if (this.$store.getters['message/notifications'].data !== undefined) {
        return this.$store.getters['message/notifications'].data.filter(
          function (obj) {
            return obj.messages_is_read === 0
          },
        ).length
      } else {
        return 0
      }
    },
  },
}
</script>
<style scoped>
.header-nav .btn {
  background: none;
}

.header-nav a {
  text-decoration: none !important;
}

.dropdown-toggle::after {
  visibility: hidden;
  display: none;
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  border: 0;
}

.notification {
  border-radius: 50%;
  position: absolute;
  background: red;
  width: 28px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 30px;
  color: #ffffff;
  font-size: 22px;
}

.nav-dropdown-toggle-branch::after {
  display: none !important;
}

.nav-dropdown-toggle-branch-button {
  border: none !important;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .is-mobile {
    display: block;
  }
  .is-pc {
    display: none;
  }
  .hamburger-menu {
    display: inline-block;
    cursor: pointer;
  }

  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
  }

  .change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
  }

  .change .bar2 {opacity: 0;}

  .change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
  }

  .mobile-menu-item {
    display: flex;
    font-size: 1.25rem;
    align-items: center;
    padding: 10px;
    border-bottom: 1px dashed #fff;
  }
  .mobile-menu-item:last-child {
    border-bottom: none;
  }
}

@media only screen and (min-width: 769px) {
  .is-mobile {
    display: none;
  }
  .is-pc {
    display: block;
  }
}
</style>
