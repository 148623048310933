import BookingsRepository from './entity/BookingsRepository'
import UsersRepository from './entity/UsersRepository'
import MessagesRepository from './entity/MessagesRepository'
import AdminRepository from '@/repositories/entity/AdminRepository'
import UploadRepository from '@/repositories/entity/UploadRepository'
import SettingsRepository from '@/repositories/entity/SettingsRepository'
import ServicesRepository from '@/repositories/entity/ServicesRepository'
import BranchesRepository from '@/repositories/entity/BranchesRepository'

const repositories = {
  bookings: BookingsRepository,
  users: UsersRepository,
  messages: MessagesRepository,
  admin: AdminRepository,
  upload: UploadRepository,
  settings: SettingsRepository,
  services: ServicesRepository,
  branches: BranchesRepository,
}

export const RepositoryFactory = {
  get: (name) => repositories[name],
}
