export default [
  {
    component: 'CNavItem',
    name: 'ダッシュボード',
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      color: 'primary',
      text: 'NEW',
    },
  },
  {
    component: 'CNavItem',
    name: '時間設定',
    to: '/datetime',
    icon: 'cil-calendar',
  },
  {
    component: 'CNavItem',
    name: '予約表',
    to: '/booking',
    icon: 'cil-address-book',
  },
]
