import { createStore } from 'vuex'
import gasStation from './modules/gas-station'
import admin from './modules/admin'
import message from './modules/message'
import user from './modules/user'
import booking from './modules/booking'
import services from './modules/services'
import lineMessages from './modules/line-messages'
import settings from "@/store/modules/settings";

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {
    gasStation,
    admin,
    message,
    user,
    booking,
    services,
    lineMessages,
    settings,
  },
})
