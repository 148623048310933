import axios from 'axios'
import moment from 'moment';
import constants from "@/utils/constants";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

export default {
    namespaced: true,
    state: {
        bookingHistories: [],
    },
    getters: {
        getBookingHistories: state => state.bookingHistories,
    },
    actions: {
        async getBookingHistories({commit }, payload) {
          const requestOptions = {
            headers: {
              "Content-Type": "application/json",
              "X-Branch-Id" : constants.X_BRANCH_ID,
              "X-App-Name":  constants.X_APP_NAME,
            }
          };
          const rs = await fetch(`${process.env.VUE_APP_API_BASE_URL}/user/${payload.userId}/booking_history`, requestOptions);
          const data =  await rs.json();
          commit('setBookingHistories', await data.data);
        },
    },
    mutations: {
        setBookingHistories(state, data) {
            let bookingHistoryList = [];
            if (data.length > 0) {
                for (const item of data) {
                    const bookingHistoryItem = {
                        bookingDateTime: `${moment(item.date).format("YYYY 年 M 月 DD 日")} `,
                        serviceName: item.gas_station_service.service.name,
                        storeName: item.gas_station_service.gas_station.name,
                        _cellProps: { id: { scope: 'row' } },
                    }
                    bookingHistoryList.push(bookingHistoryItem)
                }
            }
            state.bookingHistories = bookingHistoryList
        },
    }
}
