import Repository from '../Repository'

export default {
  getBranches() {
    return Repository.get(`/branches`)
  },

  // get current branch from local storage
  getCurrentBranch() {
    return JSON.parse(localStorage.getItem('currentBranch'))
  },

  getBranchIdFromLocalStorage() {
    if (!localStorage.getItem('currentBranch')) {
      return ''
    }
    return JSON.parse(localStorage.getItem('currentBranch')).id
  },

  getBranchNameFromLocalStorage() {
    if (!localStorage.getItem('currentBranch')) {
      return ''
    }
    const currentBranch = JSON.parse(localStorage.getItem('currentBranch'))
    return currentBranch.domain
  },
}
