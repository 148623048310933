import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { authGuard } from '@/store/modules/auth'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'ダッシュボード',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/datetime',
        name: '時間設定',
        component: () => import('@/views/datetime/Index.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/booking',
        name: '予約表',
        component: () => import('@/views/booking/Index.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/users',
        name: '顧客リスト',
        component: () => import('@/views/users/Index.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/user/:id',
        name: 'User edit',
        component: () => import('@/views/users/Edit.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/services',
        name: 'Service Index',
        component: () => import('@/views/services/Index.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/messages',
        name: 'Messages Index',
        component: () => import('@/views/messages/Index.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/messages/draft',
        name: 'message_draft',
        component: () => import('@/views/messages/Draft.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/messages/template',
        name: 'message_template',
        component: () => import('@/views/messages/Template.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/messages/remind',
        name: 'Remind',
        component: () => import('@/views/messages/Remind.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/messages/thankyou',
        name: 'ThankYou',
        component: () => import('@/views/messages/ThankYou.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/messages/history',
        name: 'history',
        component: () => import('@/views/messages/History.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/settings/Index.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/settings/qa',
        name: 'settings_qa',
        component: () => import('@/views/settings/qa.vue'),
        beforeEnter: authGuard,
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
