<template>
  <div>
<!--    <AppSidebar />-->
    <div class="wrapper d-flex flex-column min-vh-100" :class="{ 'bg-light': !isBgLight() }">
      <AppHeader />
      <div class="body flex-grow-1">
        <CContainer lg>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    // AppSidebar,
    CContainer,
  },
  methods: {
    isBgLight() {
      return this.$route.path === '/services'
      || /\/messages\/*/.test(this.$route.path)
      || /\/settings\/*/.test(this.$route.path)
    }
  },
}
</script>
