import Repository from '../Repository'

export default {
  index: () => {
    return Repository.get('admin/gas-station-services')
  },
  bookings: (payload) => {
    return Repository.get('admin/gas-station-services/bookings', {
      params: payload,
    })
  },
  create: (payload) => {
    return Repository.post('bookings', payload)
  },
  createFuelDelivery: (payload) => {
    return Repository.post(`bookings/fuel`, payload);
  },
  delete: (userId, bookingId) => {
    return Repository.delete(`user/${userId}/booking/${bookingId}`)
  },
  deleteOil: (userId, bookingId, bookingOilCanId) => {
    return Repository.delete(
      `user/${userId}/booking/${bookingId}/oil/${bookingOilCanId}`,
    )
  },
  getFuelDelivery: (payload) => {
    return Repository.get(`bookings/fuel?`, { params: payload })
  },
  getCarInspection: (payload) => {
    return Repository.get(`bookings/car-inspection?`, { params: payload })
  },
  getFuelDeliveryByDate: (payload) => {
    return Repository.get(`bookings/fuel-by-date?`, { params: payload })
  },
  getCarDeliveryByDate: (payload) => {
    return Repository.get(`bookings/car-by-date?`, { params: payload })
  },
  updateFuelStatusDelivery: (id, status) => {
    return Repository.patch(`bookings/fuel/${id}/update-status-deli/${status}`)
  },
  updateCarStatusDelivery: (id, status) => {
    return Repository.patch(`bookings/car-inspection/${id}/update-status-deli/${status}`)
  },
  checkFuelDeliveryDatetime: (id, dateTime) => {
    return Repository.get(`bookings/fuel/${id}/check-delivery-datetime/${dateTime}`)
  },
  updateFuelDeliveryDatetime: (id, dateTime) => {
    return Repository.patch(`bookings/fuel/${id}/update-delivery-datetime/${dateTime}`)
  },
  checkCarDeliveryDatetime: (id, date, timeBlockId) => {
    return Repository.get(`bookings/car-inspection/${id}/check-delivery-datetime/${date}/${timeBlockId}`)
  },
  updateCarDeliveryDatetime: (id, date, timeBlockId) => {
    return Repository.patch(`bookings/car-inspection/${id}/update-delivery-datetime/${date}/${timeBlockId}`)
  },
}
