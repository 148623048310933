import Repository from "../Repository";

export default {
  uploadImage: (payload) => {
    return Repository.post('/upload/image', payload);
  },

  uploadVideo: (payload) => {
    return Repository.post('/line-messages/upload/video', payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
