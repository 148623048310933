<template>
  <button class="m-auto bg-white py-1 px-4 border border-1" :class="{ 'border-0': !isDashboard() }" @click="logout">ログアウト </button>

<!--  <CDropdown variant="nav-item">-->
<!--    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false" href="javascript:" @click.prevent="add">-->
<!--      <CAvatar :src="avatar" size="md" />-->
<!--    </CDropdownToggle>-->
<!--    <CDropdownMenu class="pt-0">-->
<!--      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">-->
<!--        Account-->
<!--      </CDropdownHeader>-->
<!--      <CDropdownItem>-->
<!--        <CIcon icon="cil-bell" /> Updates-->
<!--        <CBadge color="info" class="ms-auto">{{ itemsCount }}</CBadge>-->
<!--      </CDropdownItem>-->
<!--      <CDropdownItem>-->
<!--        <CIcon icon="cil-envelope-open" /> Messages-->
<!--        <CBadge color="success" class="ms-auto">{{ itemsCount }}</CBadge>-->
<!--      </CDropdownItem>-->
<!--      <CDropdownItem>-->
<!--        <CIcon icon="cil-task" /> Tasks-->
<!--        <CBadge color="danger" class="ms-auto">{{ itemsCount }}</CBadge>-->
<!--      </CDropdownItem>-->
<!--      <CDropdownItem>-->
<!--        <CIcon icon="cil-comment-square" /> Comments-->
<!--        <CBadge color="warning" class="ms-auto">{{ itemsCount }}</CBadge>-->
<!--      </CDropdownItem>-->
<!--      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">-->
<!--        Settings-->
<!--      </CDropdownHeader>-->
<!--      <CDropdownItem> <CIcon icon="cil-user" /> Profile </CDropdownItem>-->
<!--      <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem>-->
<!--      <CDropdownItem>-->
<!--        <CIcon icon="cil-dollar" /> Payments-->
<!--        <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge>-->
<!--      </CDropdownItem>-->
<!--      <CDropdownItem>-->
<!--        <CIcon icon="cil-file" /> Projects-->
<!--        <CBadge color="primary" class="ms-auto">{{ itemsCount }}</CBadge>-->
<!--      </CDropdownItem>-->
<!--      <CDropdownDivider />-->
<!--      <CDropdownItem>-->
<!--        <CIcon icon="cil-shield-alt" /> Lock Account-->
<!--      </CDropdownItem>-->
<!--      <CDropdownItem @click="logout"> <CIcon icon="cil-lock-locked" /> ログアウト </CDropdownItem>-->
<!--    </CDropdownMenu>-->
<!--  </CDropdown>-->
</template>

<script>
// import avatar from '@/assets/images/avatars/8.jpg'
import avatar from '@/assets/images/avatars/Satoh.png'
import admin from '@/store/modules/admin'
import store from "@/store";
import router from "@/router";
export default {
  name: 'AppHeaderDropdownAccnt',
  // components: {CButton},
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  methods: {
    add(event) {
      event.target.className += ' disabled'
    },
    logout(){
      // remove all local storage
      localStorage.clear()
      store.commit("admin/logout")
      router.push({ path: '/pages/login' })
    },
    isDashboard() {
      return this.$route.path === '/dashboard';
    }
  }
}
</script>
