import MessagesRepository from "@/repositories/entity/MessagesRepository";

export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  getters: {
    notifications: state => state.notifications,
  },
  mutations: {
    getNotifications(state, data) {
      state.notifications = data;
    },
  },
  actions: {
    async getNotifications({ commit }) {
      try {
        const {data} = await MessagesRepository.messages();
        commit('getNotifications', data)
      } catch (error) {
        throw error
      }
    },

    async updateNotification({}, param) {
      try {
        await MessagesRepository.update(param);
      } catch (error) {
        throw error
      }
    },
  }
}
