import Repository from "../Repository";

export default {
  getSettings: () => {
    return Repository.get('/settings');
  },
  // get settings fuel price
  getFuelPrice: () => {
    return Repository.get('/settings/code/fuel_price');
  },
  //get settings question and answer
  getQuestionAndAnswer: () => {
    return Repository.get('/settings/code/q_a');
  },
  // update settings fuel price
  updateFuelPrice: (data) => {
    return Repository.put('/settings/code/fuel_price', data);
  },
  // update settings question and answer
  updateQuestionAndAnswer: (data) => {
    return Repository.put('/settings/code/q_a', data);
  },
}
