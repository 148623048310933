import MessagesRepository from '@/repositories/entity/LineMessagesRepository'
import LineMessagesRepository from '@/repositories/entity/LineMessagesRepository'

export default {
  namespaced: true,
  state: {
    drafts: [],
    templates: [],
    history: [],
    filters: [],
    lineMessage: [],
    remindTemplate: [],
    thankTemplate: [],
    lineInfo: {
      used: 0,
      limit: 0
    },
    messageCounter: 0,
  },
  getters: {
    drafts: (state) => state.drafts,
    templates: (state) => state.templates,
    history: (state) => state.history,
    remindTemplate: (state) => state.remindTemplate,
    thankTemplate: (state) => state.thankTemplate,
    lineInfo: (state) => state.lineInfo,
  },
  mutations: {
    getTemplates(state, data) {
      state.templates = data
    },
    getHistory(state, data) {
      state.history = data
    },
    getDrafts(state, data) {
      state.drafts = data
    },
    getFilters(state, data) {
      state.filters = data
    },
    show(state, data) {
      state.lineMessage = data
    },
    getRemindTemplates(state, data) {
      state.remindTemplate = data
    },
    setLineInfo(state, data) {
      state.lineInfo = data
    },
    getThankTemplates(state, data) {
      state.thankTemplate = data
    },
    setMessageCounter(state, data) {
      state.messageCounter = data;
    }
  },
  actions: {
    async getTemplates({ commit }, payload) {
      try {
        const { data } = await LineMessagesRepository.templates(payload.limit)
        commit('getTemplates', data)
      } catch (error) {
        throw error
      }
    },
    async delTemplate({ commit }, id) {
      try {
        await LineMessagesRepository.delTemplate(id)
      } catch (error) {
        throw error
      }
    },
    async getHistory({ commit }, payload) {
      try {
        const { data } = await LineMessagesRepository.history(payload.limit)
        commit('getHistory', data)
      } catch (error) {
        throw error
      }
    },
    async getDrafts({ commit }, payload) {
      try {
        const { data } = await LineMessagesRepository.drafts(payload.limit)
        commit('getDrafts', data)
      } catch (error) {
        throw error
      }
    },
    async getFilters({ commit }) {
      try {
        const { data } = await LineMessagesRepository.filters()
        commit('getFilters', data)
      } catch (error) {
        throw error
      }
    },

    async show({ commit }, id) {
      try {
        const { data } = await LineMessagesRepository.show(id)
        commit('show', data)
      } catch (error) {
        throw error
      }
    },

    async save({ commit }, payload) {
      try {
        const { data } = await LineMessagesRepository.save(payload)
        if (payload.action == 'fetch') {
          commit('setMessageCounter', data.data.number_message)
        } else {
          commit('getTemplates', data)
        }
      } catch (error) {
        throw error
      }
    },
    async update({ commit }, payload) {
      try {
        const { data } = await LineMessagesRepository.update(payload)
        commit('getTemplates', data)
      } catch (error) {
        throw error
      }
    },
    async getRemindTemplates({ commit }, payload) {
      try {
        const { data } = await LineMessagesRepository.getRemindTemplate()
        commit('getRemindTemplates', data.data)
      } catch (error) {
        throw error
      }
    },
    async getLineInfo({ commit }, payload) {
      try {
        const { data } = await LineMessagesRepository.getLineInfo()
        commit('setLineInfo', data)
      } catch (error) {
        throw error
      }
    },
    async getThankTemplates({ commit }, payload) {
      try {
        const { data } = await LineMessagesRepository.getThankTemplates()
        commit('getThankTemplates', data.data)
      } catch (error) {
        throw error
      }
    },
    async saveRemindTemplate({ commit }, payload) {
      try {
        const { data } = await LineMessagesRepository.saveRemindTemplate(
          payload,
        )
        commit('getTemplates', data)
      } catch (error) {
        throw error
      }
    },
    async saveThankTemplate({ commit }, payload) {
      try {
        const { data } = await LineMessagesRepository.saveThankTemplate(payload)
        commit('getTemplates', data)
      } catch (error) {
        throw error
      }
    },
  },
}
