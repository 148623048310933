import Repository from '../Repository'

export default {
  templates: (limit) => {
    const branchId = JSON.parse(localStorage.getItem('currentBranch')).id
    return Repository.get(
      '/line-messages/templates?limit=' + limit + '&branch_id=' + branchId,
    )
  },
  history: (limit) => {
    const branchId = JSON.parse(localStorage.getItem('currentBranch')).id
    return Repository.get(
      '/line-messages/history?limit=' + limit + '&branch_id=' + branchId,
    )
  },
  delTemplate: (templateId) => {
    return Repository.delete('/line-messages/templates/' + templateId)
  },
  drafts: (limit) => {
    const branchId = JSON.parse(localStorage.getItem('currentBranch')).id
    return Repository.get(
      '/line-messages/drafts?limit=' + limit + '&branch_id=' + branchId,
    )
  },
  filters: () => {
    return Repository.get('/line-messages/filters')
  },
  show: (id) => {
    return Repository.get('/line-messages/' + id)
  },
  update: (payload) => {
    payload.branch_id = JSON.parse(localStorage.getItem('currentBranch')).id
    return Repository.put('/line-messages/' + payload.id, payload)
  },
  save: (payload) => {
    payload.branch_id = JSON.parse(localStorage.getItem('currentBranch')).id
    return Repository.post('/line-messages', payload)
  },
  getRemindTemplate: () => {
    // get branch id from local storage
    const branchId = JSON.parse(localStorage.getItem('currentBranch')).id
    return Repository.get(`/line-messages/remind?branch_id=${branchId}`)
  },
  getLineInfo: () => {
    const branchId = JSON.parse(localStorage.getItem('currentBranch')).id
    return Repository.get(`/line-messages/quota/info?branch_id=${branchId}`)
  },
  getThankTemplates: () => {
    const branchId = JSON.parse(localStorage.getItem('currentBranch')).id
    return Repository.get(`/line-messages/thanks?branch_id=${branchId}`)
  },
  saveRemindTemplate: (payload) => {
    payload.branch_id = JSON.parse(localStorage.getItem('currentBranch')).id
    return Repository.post('/line-messages/remind', payload)
  },
  saveThankTemplate: (payload) => {
    payload.branch_id = JSON.parse(localStorage.getItem('currentBranch')).id
    return Repository.post('/line-messages/thanks', payload)
  },
}
