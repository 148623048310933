export default Object.freeze({
  OIL_SERVICE_TYPE: '2',
  OIL_OPTIONS: {
    '1': 'ホームタンク',
    '2': 'ポリタンク'
  },
  OIL_QUANTITY_MIN: '1',
  OIL_HAS_QUANTITY: '2',
  // HEADER
  X_APP_NAME: process.env.VUE_APP_NAME || 'admin',
  X_BRANCH_ID: process.env.VUE_APP_BRANCH_ID || 0,
});
