import Repository from "../Repository";

export default {
  messages: () => {
    return Repository.get('/messages?limit=10')
  },
  update: (payload) => {
    return Repository.put("/messages/" + payload.id, payload);
  }
}
