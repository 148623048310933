import AdminRepository from "@/repositories/entity/AdminRepository";

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || ''
  },
  mutations: {
    setToken(state, token, remember) {
      state.token = token
      localStorage.setItem('token', token)
      localStorage.setItem('expire', new Date().getTime() + 12 * 60 * 60 * 1000);
    },

    logout(){
      localStorage.removeItem('token')
      localStorage.removeItem('expire')
      localStorage.removeItem('remember')
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await AdminRepository.login(credentials);
        const token = response.data.access_token
        localStorage.setItem('remember', credentials.remember)
        commit('setToken', token)
      } catch (error) {
        // console.error(error)
        throw error
      }
    },
  }
}
