import axios from 'axios'
import constants from '@/utils/constants'
import BranchesRepository from '@/repositories/entity/BranchesRepository'

export default axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Branch-Id': constants.X_BRANCH_ID,
    'X-App-Name': constants.X_APP_NAME,
    'X-Current-Branch-Id': BranchesRepository.getBranchIdFromLocalStorage(),
  },
})
