import SettingsRepository from "@/repositories/entity/SettingsRepository";

export default {
  namespaced: true,
  state: {
    settingsFuelPrice: [],
    settingsQuestionAndAnswer: [],
  },
  getters: {
    settingsFuelPrice: state => state.settingsFuelPrice,
    settingsQuestionAndAnswer: state => state.settingsQuestionAndAnswer,
  },
  actions: {
    // get settings fuel price
    async getSettingsFuelPrice({commit }) {
      const {data} = await SettingsRepository.getFuelPrice();
      commit('setSettingFuelPrice', await data.data);
    },
    // get settings question and answer
    async getSettingsQuestionAndAnswer({commit }) {
      const {data} = await SettingsRepository.getQuestionAndAnswer();
      commit('setSettingQuestionAndAnswer', await data.data);
    },
    // update settings fuel price
    async updateSettingsFuelPrice({commit }, payload) {
      const {data} = await SettingsRepository.updateFuelPrice(payload);
      commit('setSettingFuelPrice', data);
    },
    // update settings question and answer
    async updateSettingsQuestionAndAnswer({commit }, payload) {
      const {data} = await SettingsRepository.updateQuestionAndAnswer(payload);
      commit('setSettingQuestionAndAnswer', data);
    },
  },
  mutations: {
    setSettingFuelPrice(state, data) {
      state.settingsFuelPrice = data;
    },
    setSettingQuestionAndAnswer(state, data) {
      state.settingsQuestionAndAnswer = data;
    }
  }
}
