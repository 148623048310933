import axios from 'axios'
import moment from 'moment'
import constants from "@/utils/constants";
import BranchesRepository from "@/repositories/entity/BranchesRepository";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
})

export default {
  namespaced: true,
  state: {
    userEditInfo: {},
    isEditSuccess: false,
    isEditError: false,
    isLoading: false,
  },
  getters: {
    getUserEditInfo: (state) => state.userEditInfo,
  },
  actions: {
    async getUserInfo({ commit, dispatch }, payload) {
      commit('setIsLoading', true)
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          'X-Branch-Id': BranchesRepository.getBranchIdFromLocalStorage(),
          'X-App-Name': BranchesRepository.getBranchNameFromLocalStorage(),
        }
      };
      const rs = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}/admin/user/${payload.userId}`,
        requestOptions
      )
      const data = await rs.json()
      commit('setUserEditInfo', await data.data)
      await dispatch("booking/getBookingHistories", { userId: payload.userId,}, { root: true });
      commit('setIsLoading', false)
    },
    async putUserInfo({ commit, dispatch }, payload) {
      commit('setIsEditSuccess', false);
      commit('setIsLoading', true)

      const data = {
        name: payload.userEditInfo.name,
        name_kana: payload.userEditInfo.name_kana,
        phone: payload.userEditInfo.phone,
        email: payload.userEditInfo.email,
        postcode: payload.userEditInfo.postcode,
        address: payload.userEditInfo.address,
        room_number: payload.userEditInfo.room_number,
        remarks: payload.userEditInfo.remarks,
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Branch-Id': BranchesRepository.getBranchIdFromLocalStorage(),
          'X-App-Name': BranchesRepository.getBranchNameFromLocalStorage(),
        },
      }

      const rs = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/user/${payload.userEditInfo.id}`,
        JSON.stringify(data),
        config,
      )
      commit('setIsEditSuccess', true);
      await dispatch('getUserInfo', {
        userId: payload.userEditInfo.id
      })
      commit('setIsLoading', false);



    },
  },
  mutations: {
    setUserEditInfo(state, data) {
      state.userEditInfo = data
      state.userEditInfo.created_at = moment(new Date(data.created_at)).format(
        'YYYY 年 M 月 DD 日',
      )
      state.userEditInfo.updated_at = moment(new Date(data.updated_at)).format(
        'YYYY 年 M 月 DD 日',
      )
    },
    setIsLoading(state, data) {
      state.isLoading = data
    },
    setIsEditSuccess(state, data) {
      state.isEditSuccess = data
    },
    setIsEditError(state, data) {
      state.isEditError = data
    },
  },
}
