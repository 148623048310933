import Repository from '../Repository'

export default {
  getServiceTypeByServiceId: (payload) => {
    return Repository.get(`/services/service_type/${payload.id}`)
  },
  getMenuByServiceTypeId: (payload) => {
    return Repository.get(`/services/menu/${payload.id}`)
  },
  getOptionByServiceTypeId: (payload) => {
    return Repository.get(`/services/option/${payload.id}`)
  },
}
