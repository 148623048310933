import constants from "@/utils/constants";

export default {
  namespaced: true, state: {
    gasStationList: [], test: "Nguyen Duc Hung"
  }, getters: {
    gasStationList: state => state.gasStationList,
  }, actions: {
    async getGasStation({commit}) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json", "X-Branch-Id": constants.X_BRANCH_ID, "X-App-Name": constants.X_APP_NAME,
        }
      };
      const rs = await fetch(`${process.env.VUE_APP_API_BASE_URL}/gas-stations`, requestOptions);
      const data = await rs.json();
      commit('setGasStationList', await data.data);
    },
  }, mutations: {
    setGasStationList(state, data) {
      state.gasStationList = data;
    },
  }
}
