import constants from "@/utils/constants";

export default {
  namespaced: true,
  state: {
    services: [],
  },
  getters: {
    services: (state) => state.services,
  },
  actions: {
    async getServices({ commit }) {
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          "X-Branch-Id" : constants.X_BRANCH_ID,
          "X-App-Name":  constants.X_APP_NAME,
        }
      };
      const rs = await fetch(`${process.env.VUE_APP_API_BASE_URL}/services`, requestOptions)
      const data = await rs.json()
      commit('setServices', await data.data)
    },
  },
  mutations: {
    setServices(state, data) {
      state.services = data
    },
  },
}
